.MainHeader {
  .navbar-brand {
    img {
      margin-top: -10px;
      margin-bottom: -10px;
      width: 36px;
    }
  }
  .navbar-nav {
    .nav-item.dropdown.bg-opacity-10:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}
