.sticky-container {
  position: sticky;
  top: 55px; 
  background-color: #fff; 
  z-index: 1; 
}

.cell-width {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
}

.cell-heading-width {
  width: 400px;
  min-width: 400px;
  max-width: 400px;
}

.cell-padding {
  padding: 0.5rem;
}

.phase-background {
  background-color: #D9D9D9 !important;
}

#costing-grid.table th:first-child,
#costing-grid.table td:first-child {
    position: -webkit-sticky; // 'position: sticky' is not supported by Safari on iOS < 13. Adding 'position: -webkit-sticky' to support Safari on iOS 7+
    position: sticky;
    left: 0;
}

.sticky-right {
    position: -webkit-sticky; // 'position: sticky' is not supported by Safari on iOS < 13. Adding 'position: -webkit-sticky' to support Safari on iOS 7+
    position: sticky;
    right: 0;
}
