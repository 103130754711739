@import url("https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Round|Material+Icons+Two+Tone|Material+Icons+Sharp");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");

@import "~bootstrap/scss/bootstrap-utilities";

//Generate utilites for Cursor
$utilities: map-merge(
  $utilities,
  (
    "cursor": (
      property: cursor,
      values: auto default pointer wait text move help not-allowed
    ),
  )
);

@import '~bootstrap/scss/bootstrap';

@import "bootstrap";

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  background-color: #f5f5f5;
  font-family: "Source Sans Pro", sans-serif;
  margin-top: 58px;
}

#root {
  -webkit-transition: 200ms linear;
  -moz-transition: 200ms linear;
  -o-transition: 200ms linear;
  -ms-transition: 200ms linear;
  transition: 200ms linear;
}

.Main {
  display: flex;
  flex-direction: column;
  min-height: 94vh;
  .Footer {
    flex: 0 0 auto;
    margin-bottom: -2px;
  }
  .MainContent,
  .Login,
  .ForgotPassword {
    flex: 1 0 auto;
  }
  .MainHeader {
    flex: 0 0 auto;
  }
}

@media (min-width: 992px) {
  .scroller {
    height: 94vh;
    position: sticky;
    top: 6vh;
    overflow: auto;
  }
}

.material-icons,
.material-icons-outlined {
  vertical-align: middle;
  margin-top: -2px;

  &.md-12 {
    font-size: 12px;
  }

  &.md-14 {
    font-size: 12px;
  }

  &.md-16 {
    font-size: 16px;
  }

  &.md-18 {
    font-size: 18px;
  }

  &.md-20 {
    font-size: 20px;
  }

  &.md-24 {
    font-size: 24px;
  }

  &.md-32 {
    font-size: 32px;
  }

  &.md-36 {
    font-size: 36px;
  }

  &.md-48 {
    font-size: 48px;
  }

  &.md-64 {
    font-size: 64px;
  }

  &.md-72 {
    font-size: 72px;
  }

  &.md-84 {
    font-size: 84px;
  }

  &.md-96 {
    font-size: 96px;
  }

  &.md-120 {
    font-size: 120px;
  }
}
